@import '../../mixins';

.listing {

  & .container {
    @include mediaMobile {
      gap: rem(35);
    }
  }

  & .product-card,
  & .news-card,
  & .services-card {
    width: 100%;
  }

  & .swiper {
    @include mediaTablet {
      overflow: visible;
    }

    &-slide {
      @include mediaTablet {
        width: rem(329);
      }

      @include mediaMobile {
        width: rem(280);
      }
    }
  }
}
